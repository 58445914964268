<template>
  <div>
    <q-item class="activity">
      <q-item-section avatar>
        <q-avatar v-if="activity.actor" class="shadow-1">
          <q-img :src="activity.actor.data.picture" />
        </q-avatar>
        <q-avatar v-else>
          <img :src="require('assets/mobilleo-logo-icon.png')">
        </q-avatar>
      </q-item-section>
      <q-item-section>
        <q-item-label>
          {{ activity.actor ? activity.actor.data.display_name : $t('system_action') }}
        </q-item-label>
        <q-item-label caption class="text-weight-medium">
          {{ activity.description }}
        </q-item-label>
      </q-item-section>
      <q-item-section side>
        <q-item-label caption>
          {{ activity.created_at | date }}
        </q-item-label>
      </q-item-section>
    </q-item>
    <q-separator />
  </div>
</template>

<script>
import date from 'utils/date-time'
import timezoneFilter from 'utils/timezoneFilter'
export default {
  filters: {
    date (data) {
      if (data.timezone === 'UTC') return timezoneFilter(data.date, 'Europe/London')
      return data ? date.toShortDateTime(data) : null
    }
  },
  props: ['item'],
  computed: {
    activity () {
      return this.item
    }
  }
}

</script>

<style lang="stylus" scoped>
  .activity
    background-color white
    height 56px
    -webkit-transition unset
    transition unset
  .hover .activity
    background-color #e1e1e1
</style>
