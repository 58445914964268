<template>
  <div>
    <q-input v-model="searchFilter" :label="$tc('filter',1)" style="padding: 0 1rem;" />
    <m-index-list
      ref="scroller"
      :load="load"
      :items="activity"
      :item-height="57"
      :reset-items="() => {activity = []}"
      :height="height"
      :filters="{ description: searchFilter }"
    >
      <template v-slot:item="{item}">
        <activity-card :item="item" />
      </template>
    </m-index-list>
  </div>
</template>

<script>
import activityCard from './activity-card'
import { MIndexList } from 'components'

export default {
  components: {
    MIndexList,
    activityCard
  },
  props: ['getter', 'height'],
  data () {
    return {
      activity: [],
      searchFilter: null
    }
  },
  methods: {
    async load (filters) {
      return this.getter(filters).then((res) => {
        this.activity = this.activity.concat(res.data.map((a, i) => {
          return {
            ...a,
            reference: this.activity.length + i
          }
        }))
        return res.meta.pagination
      })
    }
  }
}
</script>
